@charset "UTF-8";
@use "_reset";
@use "settings" as s;
@use "user_reset";
@use "common";
@use "animation";
@use "slick";
@use "slick-theme";

html,body{
	height: 100%;
}

.header{
	@include s.mq(w767){
		width: 100%;
		// position: static;
		// padding-top: 0;
		// height: 100vh;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		background: transparent;
		position: absolute;
		z-index: 1;
	}
}
/*! purgecss start ignore */

// .splash{
// 	width: 100%;
// 	height: 100vh;
// 	position: relative;
// 	//display: none;
// 	background: #fff;
// 	z-index: 100;
// 	overflow: hidden;
// 	&__box{
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		transform: translate(-50%,-50%);
// 		width: 100%;
// 	  }
// 	&__logo{
// 		width: 174px;
// 		height: auto;
// 		display: block;
// 		margin-left: auto;
// 		margin-right: auto;
// 	}
// }
// .wrapper{
// 	display: none;
// }

.kv{
	position: relative;
	animation: kv-fade 1s ease 0s ;
	//height: 100vh;
	@include s.mq(w767){
		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.3);
		}
	}
}

@keyframes kv-fade {
	0% {
	  opacity: 0;
	}
  
	// 50% {
	//   transform: scaleY(1);
	// }
  
	100% {
	  opacity: 1;
	}
}

.scroll{
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	//right: 0;
	//width: 20px;
	margin: auto;
	z-index: 10;
	// img{
	// 	width: 100vh;
	// }
	&__head{
		font-size: 1.6rem;
		letter-spacing: .1em;
		color: #fff;
	}
	&__line{
		width: 1px;
		height: 63px;
		margin: 5px auto 0 auto;
		display: block;
		background: #fff;
		transform-origin:center top;
		animation: line 3s ease 0s infinite normal none running;
	}
}

@keyframes line {
	0% {
	  transform: scaleY(0);
	}
  
	// 50% {
	//   transform: scaleY(1);
	// }
  
	100% {
	  transform: scaleY(1);
	}
  }

.slide{
//	height: 100%;
	img {
		max-width: 100%;
		max-height: 100%;
		height: 100vh;
		width: auto\9; /* ie8 */
		margin: 0 auto;
		object-fit: cover;
	}
	// @include s.mq(w767){
	// 	display: none;
	// }
}
// .slick-list{
// 	height: 100vh;
// }

/*! purgecss end ignore */


// .wrapper{
// 	@include s.mq(w767){
// 		display: block;
// 	}
// }

// .main{
// 	height: 100%;
// }

.contents{
	//width: 1396px;
	//margin: auto;
	margin-top: 30px;
	padding-right: 34px;
	display: flex;
	//justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	@include s.mq(w767){
		padding: 0 20px;
	}
}
.section{
	width: calc(50% - 15px);
	margin-top: 73px;
	@include s.mq(w587){
		width: 100%;
		margin-top: 0;
	}
	// &.stockist{
	// 	width: 100%;
	// }
	&__link{
		display: block;
		width: 100%;
		height: 100%;
		color: #fff;
		position: relative;
		&:hover{
			opacity: 1;
		}
	}
	&__head{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		z-index: 1;
		&--en{
			font-size: 5rem;
			letter-spacing: .042em;
			text-transform: uppercase;
			text-align: center;
			white-space: nowrap;
		}
		&--ja{
			font-size: 1.4rem;
			letter-spacing: .2em;
			margin-top: 10px;
		}
		&--onlinestore{
			.section__head--ja{
				transform: translateY(2.5vw);
				margin-top: 0;
				@include s.mq(w587){
					transform: translateY(6vw);
				}
			}
			
		}
		&::before{
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0;
			transition: opacity .2s;
			z-index: -1;
		}
		&:hover{
			&::before{
				opacity: .3;
			}
		}
	}
}

.products{
	margin-top: 157px;
	@include s.mq(w587){
		margin-top: 30px;
	}
}
.stockist{
	width: 100%;
	margin-top: 73px;
	@include s.mq(w587){
		margin-top: 30px;
	}
	&__img{
		@include s.mq(w587){
			display: none;
		}
		&--sp{
			display: none;
			@include s.mq(w587){
				display: block;
			}
		}	
	}
}
.others{
	margin-top: 73px;
	@include s.mq(w587){
		margin-top: 30px;
	}
}
.jin{
	margin-top: 30px;
	@include s.mq(w587){
		margin-top: 30px;
	}
}
.journal{
	margin-top: 157px;
	@include s.mq(w587){
		margin-top: 30px;
	}
}
.projects{
	margin-top: 168px;
	position: relative;
	color: #fff;
	transition: all .2s;
	&::after{
		content: 'Coming Soon';
		font-family: acumin-pro-extra-condensed,sans-serif;
		font-weight: 300;
		font-style: normal;
		font-size: 5rem;
		letter-spacing: .042em;
		text-transform: uppercase;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: block;
		z-index: 10;
		transition: all .2s;
		opacity: 0;
	}
	&:hover{
		transition: all .2s;
		.section__head--en,
		.section__head--ja{
			opacity: 0;
			transition: all .2s;
		}
		&::after{
			opacity: 1;
		}
	}
	
	@include s.mq(w587){
		margin-top: 30px;
	}
}
.about + .products{
	margin-left: 30px;
	@include s.mq(w587){
		margin-left: 0;
	}
}
.others + .journal{
	margin-left: 30px;
	@include s.mq(w587){
		margin-left: 0;
	}
}

